import React from "react";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <div>
      <div className="section full-height over-hide">	
        <div className="switch-wrap">
          <h1>HAMZA HERE 👋</h1>
          <NavLink className="nav-link" to="/portfolio"><button id="bottone1"><strong>Portfolio</strong></button></NavLink>
        </div>
      </div>	
    </div>
  );
}

export default Home;
