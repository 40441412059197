import React from "react";

function About() {
  function emoji() {
    document.getElementById("emojiContent").innerHTML =  "<h3>I was born in India</h3><h3>I love programing and hate being stuck in this looping world. Trying to break the loop.</h3><h3>Stopped all bad habits and trying to focus on my life</h3><h3>I own a company with a small team with great ambition</h3><h3>😁✌️</h3>";
  }
  return (
    <div className="about">
      <div className="section full-height over-hide">	
        <div className="switch-wrap">
          <h1>Umm 🤔, Nothing big to say...</h1>
          <h2>WAIT, I know what to say <span className="bulb" onClick={emoji}>💡</span></h2><br/>
          <div id="emojiContent">
              <h3>🇮🇳👶</h3>
              <h3>💻❤️🔄🌍💔🔄🔨🔓</h3>
              <h3>🚫🚬🚫🍺🚫🍕🔒💯👀🔛🌟</h3>
              <h3>🏢💼👥🌟🔝🌱</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
