import React from "react";

function Footer() {
  return (
    <div>
      <div className="footer">
          <div>© 2023 FriendBros. A site by Hamza with &nbsp; <span style={{color: 'red'}}>♥️</span></div>
        </div>
        <div className="cursor" id="cursor" />
        <div className="cursor2" id="cursor2" />
        <div className="cursor3" id="cursor3" />
    </div>
  );
}

export default Footer;
