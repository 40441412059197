import React from "react";

function Contact() {
  return (
    <div className="contact">
      <div class="section full-height over-hide">	
        <div class="switch-wrap">
          <a href="mailto:frndbros@gmail.com"><h1 class="contact">frndbros@gmail.com</h1></a>
          <h3>OR</h3><br />
          <a href="mailto:frndbros@gmail.com"><h1 class="contact">+91 8438 955 495</h1></a>
        </div>
      </div>	
    </div>
  );
}

export default Contact;
