// ProjectDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import sanityClient from '../client';
import BlockContent from '@sanity/block-content-to-react';

const ProjectDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[slug.current == $slug]{
          _id,
          title,
          body[]{
            ...,
            children[]{
              ...,
              // Add any other fields you need here
            }
          },
          mainImage {
            asset->{
              url
            }
          },
          categories[]->{
              title
          },
          moreImages[]{
            asset->{
              url
            }
          }
        }`;

        const data = await sanityClient.fetch(query, { slug });
        if (data.length > 0) {
          setPost(data[0]);
        } else {
          setError(new Error(`Post with slug "${slug}" not found`));
        }
        let progress = 0;
        const interval = setInterval(() => {
          progress += 10;
          setLoadingProgress(progress);
          if (progress >= 100) {
            clearInterval(interval);
            setIsLoading(false);
          }
        }, 100);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (isLoading) {
    return (
      <div className="loadingSection">
        <div>{loadingProgress}%</div>
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: `${loadingProgress}%` }}
          ></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!post) {
    return <div>Post not found.</div>;
  }

  return (
    <div className={`fade-in`}>
        <div className="postHeader">
            <h1>{post.title}</h1>
            <div className="postHeaderOverlay"></div>
            {post.mainImage && <img src={post.mainImage.asset.url} alt="Project Cover" width={"100%"} />}
        </div>
        <div className="projectBody section full-height over-hide">
            <div>
                <div className="categories">
                {post.categories && post.categories.map(category => (
                    <span
                    key={category.title}
                    className="category"
                    >
                    {category.title}
                    </span>
                ))}
                </div>
                <BlockContent blocks={post.body} />
            </div>
            <div style={{marginTop: '30px'}}>
                {post.moreImages && post.moreImages.length > 0 && (
                    <Carousel
                    showArrows={true}
                    showStatus={true}
                    showIndicators={true}
                    showThumbs={true}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={3000}
                    transitionTime={350}
                    stopOnHover={true}
                    dynamicHeight={false}>
                    {post.moreImages.map((image, index) => (
                        <div key={index}>
                        <img src={image.asset.url} alt={`Image ${index + 1}`} />
                        </div>
                    ))}
                    </Carousel>
                )}
            </div>
        </div>
    </div>
  );
};

export default ProjectDetail;
